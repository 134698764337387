export default defineNuxtRouteMiddleware((to) => {
  const userStore = useUserStore()
  const user = userStore.user

  // If the user is not logged in, redirect to login page
  if (!user) {
    return navigateTo('/', { replace: true })
  }

  // If the user is an admin, allow access
  if (user.role === 'admin') {
    return
  }

  // If the user is a member and trying to access a specific profile
  if (user.role === 'member' && to.params.id) {
    // Redirect to their own profile page
    return navigateTo('/members/view', { replace: true })
  }
})
